import { useTranslation } from "react-i18next"
import Footer from "../components/Footer";
import ScrollToTop from "../scroll-to-top";
import Header from "../components/Header/Header";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="header-img">
        <img src="/images/header-images/about-us.webp" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="/images/header-images/about-us_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p className="subtitle mb-0">{t('meet_us').toUpperCase()}</p>
          <p>{t('about_us').toUpperCase()}</p>
        </div>
      </div>
      <div className="w-1200 mx-auto about-us">
        <div>
          <div className="text-center">
            <h1 className="page-title py-5">{t('who_are_we')}?</h1>
          </div>
          <div className="gray-text" dangerouslySetInnerHTML={{ __html: t('who_are_we_text') }}></div>
          <div className="row g-0 my-5">
            <div className="col-12 col-md-6 box">
              <div>
                <div className="box-container d-flex light-pink-bg me-md-2 h-100 p-4 ">
                  <div className="icon me-3"><i className="bi bi-suit-heart-fill pink-text"></i></div>
                  <div className="mt-2">
                    <h3>{t('mission')}</h3>
                    <div>{t('mission_text')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 box">
              <div className="h-100">
                <div className="box-container d-flex light-pink-bg ms-md-2 mt-4 mt-md-0 h-100 p-4">
                  <div className="icon me-3"><i className="bi bi-suit-heart-fill pink-text"></i></div>
                  <div className="mt-2">
                    <h3>{t('goals')}</h3>
                    <div>{t('goals_text')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4 mt-md-0 box">
              <div className="row g-0">
                <div className="box-container d-flex light-pink-bg mt-4 p-4">
                  <div className="icon me-3"><i className="bi bi-suit-heart-fill pink-text"></i></div>
                  <div className="mt-2">
                    <h3>{t('special_goals')}</h3>
                    <div dangerouslySetInnerHTML={{ __html: t('special_goals_text') }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="super-light-pink-bg">
        <div className="w-1200 mx-auto">
          <div className="text-center">
            <h3 className="page-subtitle my-5 py-5">{t('foundation_team')}?</h3>
          </div>
          <div className="mt-5 pb-5 foundation-team">
            <div className="row g-0">
              <div className="col-12 col-md-6">
                <div className="text-center">
                  <img src="/images/about-us/danijela.webp" className="w-75" alt=""></img>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_danijela')}</h3>
                <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_danijela')}</i></p>
                <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_danijela') }}></div>
              </div>
            </div>
            <div className="row g-0 mt-5">
              <div className="col-12 col-md-6 order-2 order-sm-1">
                <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_jelena')}</h3>
                <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_jelena')}</i></p>
                <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_jelena') }}></div>
              </div>
              <div className="col-12 col-md-6 order-1 order-sm-2">
                <div className="text-center">
                  <img src="/images/about-us/jelena.webp" className="w-75" alt=""></img>
                </div>
              </div>
            </div>
            <div className="row g-0 mt-5">
              <div className="col-12 col-md-6">
                <div className="text-center">
                  <img src="/images/about-us/nikolina.webp" className="w-75" alt=""></img>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_nikolina')}</h3>
                <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_nikolina')}</i></p>
                <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_nikolina') }}></div>
              </div>
            </div>
            <div className="row g-0 mt-5">
              <div className="col-12 col-md-6 order-2 order-sm-1">
                <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_zivana')}</h3>
                <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_zivana')}</i></p>
                <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_zivana') }}></div>
              </div>
              <div className="col-12 col-md-6 order-1 order-sm-2">
                <div className="text-center">
                  <img src="/images/about-us/zivana.webp" className="w-75" alt=""></img>
                </div>
              </div>
            </div>
            <div className="row g-0 mt-5">
              <div className="col-12 col-md-6">
                <div className="text-center">
                  <img src="/images/about-us/nebojsa.webp" className="w-75" alt=""></img>
                  <h3 className="text-center mt-4">{t('title_nebojsa')}</h3>
                  <p className="fw-bold gray-text text-center"><i>{t('subtitle_nebojsa')}</i></p>
                  <p className="text-justify px-4" dangerouslySetInnerHTML={{ __html: t('text_nebojsa') }}></p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="text-center">
                  <img src="/images/about-us/biljana.webp" className="w-75 mt-5 mt-md-0" alt=""></img>
                  <h3 className="text-center mt-4">{t('title_biljana')}</h3>
                  <p className="fw-bold gray-text text-center"><i>{t('subtitle_biljana')}</i></p>
                  <p className="text-justify px-4" dangerouslySetInnerHTML={{ __html: t('text_biljana') }}></p>
                </div>
              </div>
            </div>
            <div className="row g-0 mt-5">
              <div className="col-12 col-md-6">
                <div className="text-center">
                  <img src="/images/about-us/sanja.webp" className="w-75" alt=""></img>
                  <h3 className="text-center mt-4">{t('title_sanja')}</h3>
                  <p className="fw-bold gray-text text-center"><i>{t('subtitle_sanja')}</i></p>
                  <p className="text-justify px-4" dangerouslySetInnerHTML={{ __html: t('text_sanja') }}></p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="text-center">
                  <img src="/images/about-us/neda.webp" className="w-75 mt-5 mt-md-0" alt=""></img>
                  <h3 className="text-center mt-4">{t('title_neda')}</h3>
                  <p className="fw-bold gray-text text-center"><i>{t('subtitle_neda')}</i></p>
                  <p className="text-justify px-4" dangerouslySetInnerHTML={{ __html: t('text_neda') }}></p>
                </div>
              </div>
            </div>
            <div className="row g-0 mt-5">
            <div className="col-12 col-md-6">
                  <div className="text-center">
                    <img src="/images/about-us/aleksandra.webp" className="w-75 mt-5 mt-md-0" alt=""></img>
                    <h3 className="text-center mt-4">{t('title_aleksandra')}</h3>
                    <p className="fw-bold gray-text text-center"><i>{t('subtitle_aleksandra')}</i></p>
                    <p className="text-justify px-4" dangerouslySetInnerHTML={{ __html: t('text_aleksandra') }}></p>
                  </div>
                </div>
              <div className="col-12 col-md-6">
                <div className="text-center">
                  <img src="/images/about-us/srdjan.webp" className="w-75" alt=""></img>
                  <h3 className="text-center mt-4">{t('title_srdjan')}</h3>
                  <p className="fw-bold gray-text text-center"><i>{t('subtitle_srdjan')}</i></p>
                  <p className="text-justify px-4" dangerouslySetInnerHTML={{ __html: t('text_srdjan') }}></p>
                </div>
              </div>

              <div className="row g-0 mt-5">
                <div className="col-12 col-md-6">
                  <div className="text-center">
                    <img src="/images/about-us/bojana.webp" className="w-75" alt=""></img>
                    <h3 className="text-center mt-4">{t('title_bojana')}</h3>
                    <p className="fw-bold gray-text text-center"><i>{t('subtitle_bojana')}</i></p>
                    <p className="text-justify px-4" dangerouslySetInnerHTML={{ __html: t('text_bojana') }}></p>
                  </div>
                </div>


              </div>



              <div className="col-12 col-md-6">
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default AboutUs