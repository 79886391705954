import './style.css';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationSR from './locales/sr.json';
import translationEN from './locales/en.json';
import Home from './pages/Home';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import AboutUs from './pages/AboutUs';
import FoundationWord from './pages/FoundationWord';
import Awords from './pages/Awords';
import Partners from './pages/Partners';
import Projects2022 from './pages/Projects2022';
import Projects2023 from './pages/Projects2023';
import FamillyHelpList2022 from './pages/FamillyHelp2022/FamillyHelpList2022';
import FamillyHelpsDetails2022 from './pages/FamillyHelp2022/FamillyHelpDetails2022';
import AssociationsSupportList2023 from './pages/AssociationsSupport2023/AssociationsSupportList2023';
import AssociationsSupportDetails2023 from './pages/AssociationsSupport2023/AssociationsSupportDetails2023';
import CooperationList2022 from './pages/Cooperation2022/CooperationList2022';
import CooperationDetails2022 from './pages/Cooperation2022/CooperationDetails2022';
import SelectedProjectsList2022 from './pages/SelectedProjects2022/SelectedProjectsList2022';
import SelectedProjectsDetails2022 from './pages/SelectedProjects2022/SelectedProjectsDetails2022';
import MakeWishList2022 from './pages/MakeWish2022/MakeWishList2022';
import MakeWishDetails2022 from './pages/MakeWish2022/MakeWishDetails2022';
import OtherActivitiesList2022 from './pages/OtherActivities2022/OtherActivitiesList2022';
import OtherActivitiesDetails2022 from './pages/OtherActivities2022/OtherActivitiesDetails2022';
import StrongWomanList2023 from './pages/StrongWoman2023/StrongWomanList2023';
import StrongWomanDetails2023 from './pages/StrongWoman2023/StrongWomanDetails2023';
import AssociationsSupportList2022 from './pages/AssociationsSupport2022/AssociationsSupportList2022';
import AssociationsSupportDetails2022 from './pages/AssociationsSupport2022/AssociationsSupportDetails2022';
import CooperationList2023 from './pages/Cooperation2023/CooperationList2023';
import CooperationDetails2023 from './pages/Cooperation2023/CooperationDetails2023';
import FamillyHelpList2023 from './pages/FamillyHelp2023/FamillyHelpList2023';
import FamillyHelpDetails2023 from './pages/FamillyHelp2023/FamillyHelpDetails2023';
import SelectedProjectsList2023 from './pages/SelectedProjects2023/SelectedProjectsList2023';
import SelectedProjectsDetails2023 from './pages/SelectedProjects2023/SelectedProjectsDetails2023';
import OtherActivitiesList2023 from './pages/OtherActivities2023/OtherActivitiesList2023';
import OtherActivitiesDetails2023 from './pages/OtherActivities2023/OtherActivitiesDetails2023';
import BecomeMember from './pages/BecomeMember';
import IndividualHelp from './pages/IndividualHelp';
import OrganizationHelp from './pages/OrganizationHelp';
import Donate from './pages/Donate';
import Finance from './pages/Finance';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Blog from './pages/Blog';
import Interviews from './pages/Interviews';
import OurStories from './pages/OurStories';
import OthersAboutUs from './pages/OthersAboutUs';
import InterviewDetails from './pages/InterviewDetails';
import OurStoriesDetails from './pages/OurStoriesDetails';
// import './assets/css/fonts.css';
import Projects2024 from "./pages/Projects2024"
import AssociationsSupportList2024 from './pages/AssociationsSupport2024/AssociationsSupportList2024';
import AssociationsSupportDetails2024 from './pages/AssociationsSupport2024/AssociationsSupportDetails2024';
import CooperationList2024 from './pages/Cooperation2024/CooperationList2024';
import CooperationDetails2024 from './pages/Cooperation2024/CooperationDetails2024';
import FamillyHelpList2024 from './pages/FamillyHelp2024/FamillyHelpList2024';
import FamillyHelpDetails2024 from './pages/FamillyHelp2024/FamillyHelpDetails2024';
import SelectedProjectsList2024 from './pages/SelectedProjects2024/SelectedProjectsList2024'
import SelectedProjectsDetails2024 from './pages/SelectedProjects2024/SelectedProjectsDetails2024';
import OtherActivitiesList2024 from './pages/OtherActivities2024/OtherActivitiesList2024';
import OtherActivitiesDetails2024 from './pages/OtherActivities2024/OtherActivitiesDetails2024';
import Projects2025 from './pages/Projects2025';
import AssociationsSupportList2025 from './pages/AssociationsSupport2025/AssociationsSupportList2025';
import AssociationsSupportDetails2025 from './pages/AssociationsSupport2025/AssociationsSupportDetails2025';
import CooperationList2025 from './pages/Cooperation2025/CooperationList2025';
import CooperationDetails2025 from './pages/Cooperation2025/CooperationDetails2025';
import FamillyHelpList2025 from './pages/FamillyHelp2025/FamillyHelpList2025';
import FamillyHelpDetails2025 from './pages/FamillyHelp2025/FamillyHelpDetails2025';
import SelectedProjectsList2025 from './pages/SelectedProjects2025/SelectedProjectsList2025';
import SelectedProjectsDetails2025 from './pages/SelectedProjects2025/SelectedProjectsDetails2025';
import OtherActivitiesList2025 from './pages/OtherActivities2025/OtherActivitiesList2025';
import OtherActivitiesDetails2025 from './pages/OtherActivities2025/OtherActivitiesDetails2025';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/o-nama",
      element: <AboutUs />,
    },
    {
      path: "/rec-osnivaca",
      element: <FoundationWord />,
    },
    {
      path: "/priznanja-i-nagrade",
      element: <Awords />,
    },
    {
      path: "/partners",
      element: <Partners />,
    },
    {
      path: "/projekti-2022",
      element: <Projects2022 />,
    },
    {
      path: "/projekti-2023",
      element: <Projects2023 />,
    },
    {
      path: "/projekti-2024",
      element: <Projects2024 />,
    },
    {
      path: "/projekti-2025",
      element: <Projects2025 />,
    },
    {
      path: "/projekti-2022/pomoc-porodicama",
      element: <FamillyHelpList2022 />,
    },
    {
      path: "/projekti-2022/pomoc-porodicama/:id",
      element: <FamillyHelpsDetails2022 />,
    },
    {
      path: "/projekti-2022/podrska-udruzenjima",
      element: <AssociationsSupportList2022 />,
    },
    {
      path: "/projekti-2022/podrska-udruzenjima/:id",
      element: <AssociationsSupportDetails2022 />,
    },
    {
      path: "/projekti-2022/saradnja-sa-lokalnim-samoupravama",
      element: <CooperationList2022 />,
    },
    {
      path: "/projekti-2022/saradnja-sa-lokalnim-samoupravama/:id",
      element: <CooperationDetails2022 />,
    },
    {
      path: "/projekti-2022/izdvojeni-projekti",
      element: <SelectedProjectsList2022 />,
    },
    {
      path: "/projekti-2022/izdvojeni-projekti/:id",
      element: <SelectedProjectsDetails2022 />,
    },
    {
      path: "/projekti-2022/pozeli-zelju",
      element: <MakeWishList2022 />,
    },
    {
      path: "/projekti-2022/pozeli-zelju/:id",
      element: <MakeWishDetails2022 />,
    },
    {
      path: "/projekti-2022/ostale-aktivnosti",
      element: <OtherActivitiesList2022 />,
    },
    {
      path: "/projekti-2022/ostale-aktivnosti/:id",
      element: <OtherActivitiesDetails2022 />,
    },
    {
      path: "/projekti-2023/osnazena-zena",
      element: <StrongWomanList2023 />,
    },
    {
      path: "/projekti-2023/osnazena-zena/:id",
      element: <StrongWomanDetails2023 />,
    },
    {
      path: "/projekti-2023/podrska-udruzenjima",
      element: <AssociationsSupportList2023 />,
    },
    {
      path: "/projekti-2024/podrska-udruzenjima",
      element: <AssociationsSupportList2024 />,
    },
    {
      path: "/projekti-2025/podrska-udruzenjima",
      element: <AssociationsSupportList2025 />,
    },
    {
      path: "/projekti-2023/podrska-udruzenjima/:id",
      element: <AssociationsSupportDetails2023 />,
    },
    {
      path: "/projekti-2024/podrska-udruzenjima/:id",
      element: <AssociationsSupportDetails2024 />,
    },
    {
      path: "/projekti-2025/podrska-udruzenjima/:id",
      element: <AssociationsSupportDetails2025 />,
    },
    {
      path: "/projekti-2023/saradnja-sa-lokalnim-samoupravama",
      element: <CooperationList2023 />,
    },
    {
      path: "/projekti-2023/saradnja-sa-lokalnim-samoupravama/:id",
      element: <CooperationDetails2023 />,
    },
    {
      path: "/projekti-2024/saradnja-sa-lokalnim-samoupravama",
      element: <CooperationList2024 />,
    },
    {
      path: "/projekti-2024/saradnja-sa-lokalnim-samoupravama/:id",
      element: <CooperationDetails2024 />,
    },
    {
      path: "/projekti-2025/saradnja-sa-lokalnim-samoupravama",
      element: <CooperationList2025 />,
    },
    {
      path: "/projekti-2025/saradnja-sa-lokalnim-samoupravama/:id",
      element: <CooperationDetails2025 />,
    },
    {
      path: "/projekti-2023/pomoc-porodicama",
      element: <FamillyHelpList2023 />,
    },
    {
      path: "/projekti-2023/pomoc-porodicama/:id",
      element: <FamillyHelpDetails2023 />,
    },
    {
      path: "/projekti-2024/pomoc-porodicama",
      element: <FamillyHelpList2024 />,
    },
    {
      path: "/projekti-2024/pomoc-porodicama/:id",
      element: <FamillyHelpDetails2024 />,
    },
    {
      path: "/projekti-2025/pomoc-porodicama",
      element: <FamillyHelpList2025 />,
    },
    {
      path: "/projekti-2025/pomoc-porodicama/:id",
      element: <FamillyHelpDetails2025 />,
    },
    {
      path: "/projekti-2023/izdvojeni-projekti",
      element: <SelectedProjectsList2023 />,
    },
    {
      path: "/projekti-2023/izdvojeni-projekti/:id",
      element: <SelectedProjectsDetails2023 />,
    },
    {
      path: "/projekti-2024/izdvojeni-projekti",
      element: <SelectedProjectsList2024 />,
    },
    {
      path: "/projekti-2024/izdvojeni-projekti/:id",
      element: <SelectedProjectsDetails2024 />,
    },
    {
      path: "/projekti-2025/izdvojeni-projekti",
      element: <SelectedProjectsList2025 />,
    },
    {
      path: "/projekti-2025/izdvojeni-projekti/:id",
      element: <SelectedProjectsDetails2025 />,
    },
    {
      path: "/projekti-2023/ostale-aktivnosti",
      element: <OtherActivitiesList2023 />,
    },
    {
      path: "/projekti-2023/ostale-aktivnosti/:id",
      element: <OtherActivitiesDetails2023 />,
    },
    {
      path: "/projekti-2024/ostale-aktivnosti",
      element: <OtherActivitiesList2024 />,
    },
    {
      path: "/projekti-2024/ostale-aktivnosti/:id",
      element: <OtherActivitiesDetails2024 />,
    },
    {
      path: "/projekti-2025/ostale-aktivnosti",
      element: <OtherActivitiesList2025 />,
    },
    {
      path: "/projekti-2025/ostale-aktivnosti/:id",
      element: <OtherActivitiesDetails2025 />,
    },
    {
      path: "/kako-da-postanem-korisnik",
      element: <BecomeMember />,
    },
    {
      path: "/individualna-pomoc",
      element: <IndividualHelp />,
    },
    {
      path: "/pomoc-za-udruzenja-i-organizacije",
      element: <OrganizationHelp />,
    },
    {
      path: "/doniraj",
      element: <Donate />,
    },
    {
      path: "/finansije",
      element: <Finance />,
    },
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "/intervjui",
      element: <Interviews />,
    },
    {
      path: "/nase-price",
      element: <OurStories />,
    },
    {
      path: "/drugi-o-nama",
      element: <OthersAboutUs />,
    },
    {
      path: "/intervjui/:id",
      element: <InterviewDetails />,
    },
    {
      path: "/nase-price/:id",
      element: <OurStoriesDetails />,
    }
  ]);

  const resources = {
    sr: {
      translation: translationSR
    },
    en: {
      translation: translationEN
    }
  };

  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: "sr",
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

  return (
    <RouterProvider router={router} >
    </RouterProvider>
  );
}

export default App;
