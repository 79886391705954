import Footer from "../components/Footer";
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import ScrollToTop from "../scroll-to-top";
import projectCards2025 from "../data/ProjectCards2025.json"
import Header from "../components/Header/Header";

const Projects2025 = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="header-img">
        <img src="/images/header-images/aktuelnosti.webp" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="/images/header-images/aktuelnosti_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p>{t('projects_2025').toUpperCase()}</p>
        </div>
      </div>
      <div className="w-1200 mx-auto my-5 py-5">
        <div className="row g-4">
          {projectCards2025.map((item, key) => (
            <div className="col-12 col-sm-6 col-md-4" key={key}>
              <Link to={item.href} className="text-decoration-none">
                <div className="text-center project-card d-flex flex-column align-items-center justify-content-center" role="button">
                  <img src={`images/icons/${item.icon}.webp`} alt=""></img>
                  <h5 className="mt-2">{t(item.title)}</h5>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Projects2025