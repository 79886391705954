import Footer from "../../components/Footer"
import Header from "../../components/Header/Header"
import ProjectsDetails2025 from "../../components/ProjectDetails2025"
import ScrollToTop from "../../scroll-to-top"

const SelectedProjectsDetails2025 = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <ProjectsDetails2025 />
      <Footer />
    </>
  )
}
export default SelectedProjectsDetails2025